<template>
  <div> 
    <LiefengContent>
      <template v-slot:title>广州市社区上线情况</template>
      <template v-slot:toolsbarLeft>
          <div class="btn-div" >
              <div v-for="(item,index) in menuList" :key="index" :class="menuIndex == index ?'item active':'item'" @click="chooseItem(index)">{{item.name}}</div>
              <div class="time">更新时间：{{updateDate}}</div>
          </div>
      </template>
      <template v-slot:toolsbarRight>
      <Button type="primary" @click="exportExcel">导出报表</Button>
      </template>
      <template v-slot:contentArea>
          <LiefengTable
          :talbeColumns="menuIndex == 0 ? talbeColumns : menuIndex == 1 ? talbeColumns2 :''"
          :tableData="tableData"
          :loading="loading"
          :hidePage="true"
          :fixTable="true"
          ref="table"
          :showsummary="true"
          :summaryData="summary"
          ></LiefengTable>
      </template>
    </LiefengContent>
  </div>
</template>


<script>
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      talbeColumns:[
           {
                title:'区',
                align: 'center',
                minWidth:200,
                key:0
            },
           {
                title:'示范社区数',
                align: 'center',
                minWidth:200,
                key:1
            },
           {
                title:'非示范社区数',
                align: 'center',
                minWidth:200,
                key:2
            },
           {
                title:'用户覆盖社区数',
                align: 'center',
                minWidth:200,
                key:3
            },
           {
                title:'建设动员体系社区数',
                align: 'center',
                minWidth:200,
                key:4
            },
           {
                title:'总社区数',
                align: 'center',
                minWidth:200,
                key:5
            },
                  
      ],
      talbeColumns2:[
            {
                title:'区',
                align: 'center',
                minWidth:200,
            },
           {
                title:'按街道',
                align: 'center',
                minWidth:200,
            },
            {
                title:'示范社区数',
                align: 'center',
                minWidth:200,
            },
           {
                title:'非示范社区数',
                align: 'center',
                minWidth:200,
            },
           {
                title:'用户覆盖社区数',
                align: 'center',
                minWidth:200,
            },
           {
                title:'建设动员体系社区数',
                align: 'center',
                minWidth:200,
            },
           {
                title:'总社区数',
                align: 'center',
                minWidth:200,
            },
            
      ],
      tableData:[],
      loading:false,
      page:1,
      pageSize:20,
      total:0,

      menuList:[{name:'按区'},{name:'按街道'}],
      menuIndex:0,
      menuName:'按区',
      
      updateDate:'',

      monthList:[],
      seasonList:[],
      showTable:false,

      summary:[]
    };
  },
  methods: {
    //   导出报表
      exportExcel(){
          this.$Modal.confirm({
            title: "导出提醒",
            content: "<p>正在导出当前表格数据，是否继续？</p>",
             onOk: () => {
                let tHeader = [];
                let filterVal = [];
                if(this.menuIndex == 0){
                    tHeader = ['区','示范社区数','非示范社区数','用户覆盖社区数','建设动员体系社区数','总社区数']  
                }else if(this.menuIndex == 1){
                    tHeader = ['区','街道','示范社区数','非示范社区数','用户覆盖社区数','建设动员体系社区数','总社区数']  
                }
                this.tableData.map((item,index) =>{
                    if(item && item.length != 0){
                        item.map((items,indexs) =>{
                            if(index == 0){
                                filterVal.push(indexs)
                            }
                        })
                    }
                })
                let tableData = JSON.parse(JSON.stringify(this.tableData));
                this.$core.exportExcel(tHeader, filterVal, tableData, `广州市${this.menuName}上线情况`);
             }
          })
      },
      chooseItem(index){
        this.menuIndex = index
        if(index == 0){
            this.showTable = false
            this.menuName = '按区'
            this.getList('/statistic/api/symanage/pc/syuser/statMonthUserByZone')
        }else if(index == 1){
            this.showTable = false
            this.menuName = '按街道'
             this.getList('/statistic/api/symanage/pc/syuser/statMonthUserByStreet')
        }
      },
    
    // 获取当前时间的前一天
    getUpdateDate(){
        var day1 = new Date();
        day1.setTime(day1.getTime()-24*60*60*1000);
        var s1 = day1.getFullYear()+"-" + (day1.getMonth()+1) + "-" + day1.getDate() + ' ' + '00:00:00';

        this.updateDate = s1
    },
    //  按区接口
     async getList(url){
          this.loading = true
         await this.$get(url).then(res=>{
              this.loading = false
              if(res.code == 200){
                  this.tableData = res.dataList.pop()
                  // this.summary = []
                
                  // for(let k in res.dataList[res.dataList.length - 1]){
                  //   this.summary[k] = {
                  //     key:k,
                  //     value:res.dataList[res.dataList.length - 1][k]
                  //   }
                  // }
                  // this.summary = res.dataList[res.dataList.length - 1]  
              }else{
                  this.$Message.error({
                      content:'获取数据失败',
                      background:true
                  })
                  this.tableData = []
                  return
              }
          })
      },
  
    // 
  },
  async created() {
    await this.getList('/statistic/api/symanage/pc/syuser/statMonthUserByZone')
     this.getUpdateDate()
  },

  components: {
    LiefengContent,
    LiefengTable
  }
};
</script>

<style lang="less" scoped>
.btn-div{
    display: flex;
    line-height: 30px;
    width: 1000px;
    .item{
        border: 1px solid #ccc;
        color: black;
        padding: 0 20px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        user-select: none;
    }
    .active{
        background: #2D8cF0;
        color: #ffffff;
    }
}
/deep/ .ivu-page-item-jump-next:after,
    .ivu-page-item-jump-prev:after {
        content: "\2022\2022\2022" !important;
}
</style>    